import React from 'react'
import PageContainer from '../../components/PageContainer/PageContainer'
import Title from '../../components/Title/Title'
import Layout from '../../Layouts/Layout'

const Thanks = () => (
  <Layout>
    <section>
      <PageContainer>
        <Title>Thank you!</Title>
        <p>Thank you, I will reply to you soon!</p>
      </PageContainer>
    </section>
  </Layout>
)

export default Thanks
